<template>
  <div class="container">
    <a-breadcrumb style="padding: 16px 0; background: #f0f2f5">
      <a-breadcrumb-item>单位管理</a-breadcrumb-item>
      <a-breadcrumb-item>单位信息</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="mainBox" style="padding: 24px">
      <div style="width: 80%; margin: 0px auto">
			
			<a-form-model ref="batchForm" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
				<div class="form-title">单位信息</div>
				<a-form-model-item label="食堂名称">
					<a-input v-model="tmp.title" style="width: 400px" />
				</a-form-model-item>
				<a-form-model-item label="单位性质">
					<a-select :value="tmp.type" style="width: 400px" @change="changeType" :options="options">
					</a-select>
				</a-form-model-item>
				<div class="form-title">超级管理员信息</div>
				<a-form-model-item label="账号">
					<a-input v-model="tmp.username" style="width: 400px" />
				</a-form-model-item>
				<a-form-model-item label="联系人">
					<a-input v-model="tmp.linkman" style="width: 400px" />
				</a-form-model-item>
				<a-form-model-item label="手机号">
					<a-input v-model="tmp.tel" disabled style="width: 400px" />
					<a-button type="primary" @click="showMobileModal" class="e-space">
						更换手机
					</a-button>
				</a-form-model-item>
				<!-- <a-form-model-item label="所在地">
					<v-distpicker :province="tmp.province" :city="tmp.city" :area="tmp.county" @province="onChangeProvince"
              @city="onChangeCity" @area="onChangeCounty"></v-distpicker>
					<a-input v-model="tmp.province" disabled style="width:150px" class="mr-10"/>
					<a-input v-model="tmp.city" disabled style="width:150px" class="mr-10"/>
					<a-input v-model="tmp.county" disabled style="width:150px" class="mr-10"/>
				</a-form-model-item> -->
				<a-form-model-item :wrapper-col="{ span: 14, offset: 3 }">
					<a-button type="primary" @click="doEditInstInfo"> 提交 </a-button>
				</a-form-model-item>
			</a-form-model>
      </div>
    </div>
    <!-- 新增/修改 -->
    <a-modal v-model="mobileModalVisible" title="修改手机号" cancelText="取消" :width="500" @ok="checkOldMobile()">
      <a-form-model ref="batchForm" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
        <a-form-model-item label="手机号">
          <a-input v-model="tmp.tel" disabled />
        </a-form-model-item>
        <a-form-model-item label="验证码">
          <a-input v-model="smsCode" style="width: 200px" />
          <a-button type="primary" @click="doSendSmsCode" class="e-space" :disabled="smsBtnDisabled1">
            {{ smsBtnTitle1 }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal v-model="newMobileModalVisible" title="修改手机号" cancelText="取消" :width="500" @ok="updateMobile()">
      <a-form-model ref="batchForm" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
        <a-form-model-item label="手机号">
          <a-input v-model="newMobile" />
        </a-form-model-item>
        <a-form-model-item label="验证码">
          <a-input v-model="newSmsCode" style="width: 200px" />
          <a-button type="primary" @click="doSendNewSmsCode" class="e-space" :disabled="smsBtnDisabled2">
            {{ smsBtnTitle2 }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getInstInfo,
  sendSmsCode,
  checkSmsCode,
  updateInstMobile,
  editInstInfo,
} from "@/request/api";
import VDistpicker from "v-distpicker";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    VDistpicker,
  },
  data () {
    return {
      tmp: {
        id: "",
        title: "",
        trade: "IT/互联网/通信/电子",
        province: "福建省",
        city: "漳州市",
        county: "芗城区",
        staff_count: 200,
        linkman: "",
        tel: "",
        email: "",
        order_weight: 0,
        is_valid: 1,
		username:''
      },
      options: [
        {
          value: 1,
          label: "行政事业单位",
        },
        {
          value: 2,
          label: "国有企业",
        },
        {
          value: 3,
          label: "民营企业",
        },
        {
          value: 4,
          label: "园区/社区",
        },
        {
          value: 5,
          label: "其他",
        },
      ],
      mobileModalVisible: false,
      smsCode: "",
      newMobile: "",
      newMobileModalVisible: false,
      newSmsCode: "",
      smsBtnTitle1: "发送验证码",
      smsBtnTitle2: "发送验证码",
      smsBtnDisabled1: false,
      smsBtnDisabled2: false,
      intervalSeconds: 60,
      timeId: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    instInfo () {
      getInstInfo().then((res) => {
        this.tmp = res.data;
      });
    },
    showMobileModal () {
      this.mobileModalVisible = true;
    },
    isMobile: function (str) {
      let regexp = /^1[3456789]\d{9}$/;
      return regexp.test(str);
    },
    isSmsCode: function (str) {
      let regexp = /^[0-9]{4,6}$/;
      return regexp.test(str);
    },
    doSendSmsCode () {
      if (!this.isMobile(this.tmp.tel)) {
        this.$message.error("手机格式错误");
        return;
      }
      let p = {
        mobile: this.tmp.tel,
      };
      let th = this;
      sendSmsCode(p)
        .then((res) => { })
        .catch((err) => {
          this.disableLoading = false;
          if (err.status == 201) {
            this.$message.success("操作成功");
            th.smsBtnDisabled1 = true;
            th.timeId = setInterval(() => {
              th.intervalSeconds--;
              th.smsBtnTitle1 = th.intervalSeconds + "S";
              if (th.intervalSeconds == 0) {
                th.smsBtnTitle1 = "发送验证码";
                th.smsBtnDisabled1 = false;
                clearInterval(th.timeId);
                th.intervalSeconds = 60;
              }
            }, 1000);
          } else {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        });
    },
    doSendNewSmsCode () {
      if (!this.isMobile(this.newMobile)) {
        this.$message.error("手机格式错误");
        return;
      }
      let p = {
        mobile: this.newMobile,
      };
      let th = this;
      sendSmsCode(p)
        .then((res) => { })
        .catch((err) => {
          this.disableLoading = false;
          if (err.status == 201) {
            this.$message.success("操作成功");
            th.smsBtnDisabled2 = true;
            th.timeId = setInterval(() => {
              th.intervalSeconds--;
              th.smsBtnTitle2 = th.intervalSeconds + "S";
              if (th.intervalSeconds == 0) {
                th.smsBtnTitle2 = "发送验证码";
                th.smsBtnDisabled2 = false;
                clearInterval(th.timeId);
                th.intervalSeconds = 60;
              }
            }, 1000);
          } else {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        });
    },
    checkOldMobile () {
      if (!this.isMobile(this.tmp.tel)) {
        this.$message.error("手机格式错误");
        return;
      }
      if (!this.isSmsCode(this.smsCode)) {
        this.$message.error("验证码格式错误");
        return;
      }
      let p = {
        mobile: this.tmp.tel,
        yzm: this.smsCode,
      };
      checkSmsCode(p)
        .then((res) => { })
        .catch((err) => {
          this.disableLoading = false;
          if (err.status == 201) {
            this.$message.success("操作成功");
            this.mobileModalVisible = false;
            this.newMobileModalVisible = true;
          } else {
            this.$message.error(err.data.msg);
          }
        });
    },
    changeType (v) {
      this.tmp.type = v;
    },
    updateMobile () {
      if (!this.isMobile(this.newMobile)) {
        this.$message.error("手机格式错误");
        return;
      }
      if (!this.isSmsCode(this.newSmsCode)) {
        this.$message.error("验证码格式错误");
        return;
      }
      let p = {
        mobile: this.newMobile,
        yzm: this.newSmsCode,
      };
      updateInstMobile(p)
        .then((res) => { })
        .catch((err) => {
          if (err.status == 201) {
            this.$message.success("操作成功");
            this.tmp.mobile = this.newMobile;
            this.newMobileModalVisible = false;
          } else {
            this.$message.error(err.data.msg);
          }
        });
    },
    doEditInstInfo () {
      let p = {
        title: this.tmp.title,
        type: this.tmp.type,
        linkman: this.tmp.linkman,
        province: this.tmp.province,
        city: this.tmp.city,
        county: this.tmp.county,
        username: this.tmp.username
      };
      editInstInfo(p)
        .then((res) => { })
        .catch((err) => {
          if (err.status == 201) {
            this.$message.success("操作成功");
          } else {
            this.$message.error(err.data.msg);
          }
        });
    },
    onChangeProvince (v) {
      this.tmp.province = v.value;
    },
    onChangeCity (v) {
      this.tmp.city = v.value;
    },
    onChangeCounty (v) {
      this.tmp.county = v.value;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.instInfo();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.controlBox {
  display: flex;
  .btn {
    margin-bottom: 10px;
    margin-right: 20px;
  }
}
.e-space {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.form-title{
	font-weight: 900;
	padding:12px 0 12px 20px;
}
</style>